import JSZip from "jszip";


export const parseFileName = (fileName) => {
  // Risk types and special case mapping
  const riskTypes = [    
    "Moderately Aggressive", 
    "Very Aggressive", 
    "Aggressive", 
    "Moderately Conservative", 
    "Conservative", 
    "Moderate"
  ];
  
  const specialCases = {
    "USEXX": "Tax Exempt",
    "UATXX": "Tax"
  };

  // Regex patterns to extract product_id and the potential risk level
  const productIdPattern = /^PRD[\s_]+VCM[\s_]+\d+/; // Matches "PRD_VCM_0002" or "PRD VCM 0002"
  
  // Extract product_id
  const product_id = productIdPattern.test(fileName) ? 
    fileName.match(productIdPattern)[0].replace(/[\s_]+/, ':').replace(/_/g, ':') : ''; // Converts "PRD_VCM_0002" to "PRD:VCM:0002"

  // Check for special case "USEXX" first
  const specialCaseRiskLevel = Object.keys(specialCases).find(key => fileName.includes(key));
  if (specialCaseRiskLevel) {
    return {
      fileName,
      product_id,
      risk_level: specialCases[specialCaseRiskLevel]
    };
  }

  // Check if any of the risk types appear in the filename
  let risk_level = '';  
  for (let type of riskTypes) {

    const normalizedType = type.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    const normalizedFileName = fileName.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    
    if (normalizedFileName.includes(normalizedType)) {
      risk_level = type;
      break;
    }
  }

  // If no match found, return risk_level as an empty string
  if (!risk_level) {
    risk_level = '';
  }

  return {
    fileName,
    product_id,
    risk_level
  };
}


// Check if the file is a PDF based on the file extension
export const isPdfFile = (filePath) => {
  const validPdfExtensions = ['.pdf'];
  return validPdfExtensions.some(ext => filePath.toLowerCase().endsWith(ext));
};

// Extract the file name from the path
export const extractFilename = (path) => {
  const parts = path.split(/[\\/]/); // Split by both slashes
  return parts.pop() || ''; // Return the last part (filename)
};

// Create a File object from a binary blob
export const createFileFromBlob = (fileName, contentBlob) => {
  const lastModified = new Date().getTime(); // Get the current timestamp
  let trimmedPath = extractFilename(fileName); // Extract filename

  const file = new File([contentBlob], fileName, {
    type: 'application/pdf',
    lastModified,
  });

  const result = parseFileName(trimmedPath);
  return { ...result, fileContent: file };
};

const sanitizeFilePath = (filePath) => {
  return filePath.replace(/^(\.\.\/|\.\.\\)/g, ''); // Prevent directory traversal
};

// Extract files from a ZIP archive and process the PDF files
export const extractFilesFromZip = async (zip, maxFileCount = 100, maxSizeMB = 50) => {
  const fileData = [];
  const promises = [];
  let totalSize = 0;

  zip.forEach((relativePath, zipEntry) => {
    // If too many files or exceeding max size, reject the ZIP.
    if (fileData.length >= maxFileCount || totalSize > maxSizeMB * 1024 * 1024) {
      throw new Error('Too many files or file size exceeds limit.');
    }

    // Sanitize the file path to prevent directory traversal
    const safePath = sanitizeFilePath(relativePath);

    // Process only PDFs
    if (!zipEntry.dir && isPdfFile(safePath)) {
      const filePromise = processZipEntry(zipEntry, safePath, fileData);
      promises.push(filePromise);
      totalSize += zipEntry._data.uncompressedSize;  // Track total size
    }
  });

  await Promise.all(promises);
  return fileData;
};

// Process each ZIP entry and convert it into a file object
const processZipEntry = async (zipEntry, relativePath, fileData) => {
  const zipContent = await zipEntry.async("arraybuffer");
  const fileParsedData = createFileFromBlob(relativePath, zipContent);
  fileData.push(fileParsedData);
};

// Load the ZIP file content and return extracted file data
export const handleZipFileUpload = async (arrayBuffer) => {
  const zip = await JSZip.loadAsync(arrayBuffer);
  const fileData = await extractFilesFromZip(zip);
  return fileData;
};



