import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import '../Common/Styles.css';
import { ConfirmModalFixedWidth } from 'common';
import { uploadDocumentConfirmation } from 'shared/Actions/DocumentCenterActions';

const assignObj = (obj) => obj;

class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadingFile: null,
            uploadProgress: 0,
            uploadedFileName: '',
            isFileUploaded: false,
            fileUploadConfirmationModal: false,
        };
    }



    handleFileUpload = (event,documentInfo,index)=> {
        const file = event.target.files[0];
        this.props.handleAttachedFiles(file,documentInfo,index)

        // hide zip uploader option
        this.props.hideZipUploadOption()

        if (file) {
            this.setState({
                uploadingFile: file,
                uploadProgress: 0,
                uploadedFileName: file.name,
                isFileUploaded: false,
            });

        
        const newInput = document.createElement('input');
        newInput.type = 'file';
        newInput.style.display = 'none';
        newInput.addEventListener('change', (event) => this.handleFileUpload(event, documentInfo, index));
        event.target.parentNode.replaceChild(newInput, event.target);
        }
    };

    handleSubmit = () => {
        this.setState({ fileUploadConfirmationModal: true });
    };

    closefileUploadConfirmationModal = () => {
        this.setState({ 
            fileUploadConfirmationModal: false, 
            uploadProgress: 0,
            uploadingFile: null,
            uploadedFileName: ''
        });
       
    }

   

    confirmUpload = () => {
        this.props.handleConfirmationClick()
        this.setState({
            fileUploadConfirmationModal: false,
            uploadingFile: null,
            uploadProgress: 0,
            uploadedFileName: '',
            isFileUploaded: false
        });
       
    }

    render() {
        const { uploadingFile, uploadProgress, uploadedFileName, isFileUploaded, fileUploadConfirmationModal } = this.state;
        const {documentInfo} =this.props
        const modalBodyText = (documentInfo?.fileObject[0]?.percent == 100)
            ? `Are you sure you want to upload ${this.props.documentInfo?.fileObject[0]?.fileData?.name}?`
            : 'Are you sure you want to upload?';
        return (
            <>
                {fileUploadConfirmationModal &&
                    <ConfirmModalFixedWidth
                        modalTitle="Confirmation"
                        modalBodyText={modalBodyText}
                        secondaryButtonText={"No"}
                        onPrimaryClick={this.confirmUpload}
                        onSecondaryClick={this.closefileUploadConfirmationModal}
                        primaryButtonText={"Yes"}
                    />
                }
                <div>
                    <label className='linkview'>
                        Upload New PDF
                        <input
                            type='file'
                            style={{ display: 'none' }}
                            onChange={(event)=>this.handleFileUpload(event,this.props.documentInfo,this.props.index)}
                        />
                    </label>
                    {uploadingFile && !isFileUploaded && (
                        <div>
                            <p className='mb-2'>{uploadedFileName} </p>
                            <ProgressBar now={documentInfo?.fileObject[0]?.percent} active label={`${documentInfo?.fileObject[0]?.percent}%`} />
                            {(documentInfo?.fileObject[0]?.percent == 100) &&
                                 <button className='FileSubmitBtn mt-2 ' onClick={this.handleSubmit}>Submit</button>
                            }
                        </div>
                    )}
                   
                </div>
            </>
        );
    }
}

export default FileUploader;
