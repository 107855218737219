import React from "react";
import styled from 'styled-components';
import { handleZipFileUpload } from "./zipUploadUtils";

const StyledFileInput = styled.label`
    display: inline-block;
    padding: 10px 20px;
    background-color: #486d90;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    position: relative;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #365572;
    }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: #486d90;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  position: relative;
  transition: background-color 0.3s ease;
`;

const BulkZipUploadComponent = (props) => {
  const { handleZipBulkUpload, showBulkUploadingSection } = props;

  // Handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".zip")) {
      const reader = new FileReader();
      reader.onload = async () => {
        const fileData = await handleZipFileUpload(reader.result);
        handleZipBulkUpload(fileData);
      };
      reader.readAsArrayBuffer(file);
      showBulkUploadingSection(true);
    } else {
      alert("Please upload a valid ZIP file.");
    }
  };

  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <h3 style={{marginRight: "10px"}}>Bulk Upload</h3>
      {/* <input type="file" id="zip-file-upload" onChange={handleFileUpload} /> */}
      <div>
        {/* <FileInputLabel htmlFor="file-upload">{ "Choose Zip file"}</FileInputLabel> */}
        <StyledFileInput htmlFor="zip-file-upload">Choose ZIP File</StyledFileInput>
        <HiddenFileInput
          type="file"
          id="zip-file-upload"
          data-testid="zip-file-upload"
          onChange={handleFileUpload}
          aria-label="Upload file"
        />
      </div>
    </div>
  );
};

export default BulkZipUploadComponent;
