import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { WButton, WMessageBar, WSpinner } from '../../../common';
import '../Common/Styles.css';
import Styles from './PortfolioStyles';
import { HEADER } from './Constants';
import pdfIcon from '../../../assets/icon_pdf.png';
import FileUploader from './FileUploader';
import { fileUploadWithProgressBarUpdate } from 'shared/Actions/MessageCenterActions';
import { StatusMessage } from 'modules/CustomerManagementModule/components';
import BulkZipUploadComponent from './BulkZipUploadComponent';

const cancelButtonStyles = {
  color: 'rgb(0, 74, 152)',
  marginBottom: '15px',
  marginLeft: '13px',
};

const File_UPLOAD_ERROR_MESSAGE = "File upload failed due to the technical error."

const defaultArray = ['0002CONSERVATIVE',
                      '0002MODERATELYCONSERVATIVE',
                      '0002MODERATE',
                      '0002MODERATELYAGGRESSIVE',
                      '0002AGGRESSIVE',
                      '0002VERYAGGRESSIVE',
                      '0010CONSERVATIVE',
                      '0010MODERATELYCONSERVATIVE',
                      '0010MODERATE',
                      '0010MODERATELYAGGRESSIVE',
                      '0010AGGRESSIVE',
                      '0010VERYAGGRESSIVE',
                      '0011CONSERVATIVE',
                      '0011MODERATELYCONSERVATIVE',
                      '0011MODERATE',
                      '0011MODERATELYAGGRESSIVE',
                      '0011AGGRESSIVE',
                      '0011VERYAGGRESSIVE',
                      '0012CONSERVATIVE',
                      '0012MODERATELYCONSERVATIVE',
                      '0012MODERATE',
                      '0012MODERATELYAGGRESSIVE',
                      '0012AGGRESSIVE',
                      '0012VERYAGGRESSIVE',
                      '0013CONSERVATIVE',
                      '0013MODERATELYCONSERVATIVE',
                      '0013MODERATE',
                      '0013MODERATELYAGGRESSIVE',
                      '0013AGGRESSIVE',
                      '0013VERYAGGRESSIVE',
                      '0014CONSERVATIVE',
                      '0014MODERATELYCONSERVATIVE',
                      '0014MODERATE',
                      '0014MODERATELYAGGRESSIVE',
                      '0014AGGRESSIVE',
                      '0014VERYAGGRESSIVE',
                      '0015CONSERVATIVE',
                      '0015MODERATELYCONSERVATIVE',
                      '0015MODERATE',
                      '0015MODERATELYAGGRESSIVE',
                      '0015AGGRESSIVE',
                      '0015VERYAGGRESSIVE',
                      '0003UATXX',
                      '0004CONSERVATIVE',
                      '0004MODERATELYCONSERVATIVE',
                      '0004MODERATE',
                      '0004MODERATELYAGGRESSIVE',
                      '0004AGGRESSIVE',
                      '0004VERYAGGRESSIVE',
                      '0005CONSERVATIVE',
                      '0005MODERATELYCONSERVATIVE',
                      '0005MODERATE',
                      '0005MODERATELYAGGRESSIVE',
                      '0005AGGRESSIVE',
                      '0005VERYAGGRESSIVE',
                      '0006USEXX',
                      '0007CONSERVATIVE',
                      '0007MODERATELYCONSERVATIVE',
                      '0007MODERATE',
                      '0007MODERATELYAGGRESSIVE',
                      '0007AGGRESSIVE',
                      '0007VERYAGGRESSIVE',
                      '0008CONSERVATIVE',
                      '0008MODERATELYCONSERVATIVE',
                      '0008MODERATE',
                      '0008MODERATELYAGGRESSIVE',
                      '0008AGGRESSIVE',
                      '0008VERYAGGRESSIVE',
                      '0009CONSERVATIVE',
                      '0009MODERATELYCONSERVATIVE',
                      '0009MODERATE',
                      '0009MODERATELYAGGRESSIVE',
                      '0009AGGRESSIVE',
                      '0009VERYAGGRESSIVE',
                      ]
const assignObj = obj => obj;

class PortfolioPlannerPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentIdResponse: null,
      renderCountDocumentPdfList: 0,
      renderCountDocumentList: 0,
      downloadDocumentRenderCount: 0,
      loadingStatus: false,
      saveAs: '',
      successCountState: 0,
      countState: 0,
      errorMessageFileUpload: { message: "" },
      attachedFiles: [],
      documentTableList: [],
      selectedIndex: 0,
      show: false,
      showAlert: false,
      uploadDocumentSuccessMessage: 'Document updated Successfully',
      downloadFlag: true,
      showZipUploadOption: true,
      zipUploadLoader: false,
      isBulkUploding: false,
      zipUploadData: {
        matchingItems: [],
        nonMatchingItems: [],
        duplicateItems: []
      },
      zipUploadTotalFiles: 0,
      isSubmitButtonDisabled: false
    };


  }
  componentDidMount() {
    const { getDocumentPdfList } = this.props
    getDocumentPdfList({
      "tokenType": "accessToken"
    });
    this.setState({
      loadingStatus: true
    })

  }

  showBulkUploadingSection = (show) => {
    this.setState({ isBulkUploding: show })
  }

  handleAttachedFiles = (fileData, documentInfo, index) => {
    let documentListData = this.state.documentTableList
    documentListData[index].fileObject = [{
      fileData: fileData,
      percent: 0
    }]

    this.setState({
      documentTableList: documentListData
    }, () => { this.uploadFilesWithProgressBar(this.state.documentTableList[index], documentInfo, index) })
  }

  handlegetDocumentPdfList = () => {
    const { dashboardDocumentData, documentPreferenceData } = this.props
    const { renderCountDocumentPdfList } = this.state
    const { documentPdfListSuccess, documentPdfListErrorMessage } = dashboardDocumentData

    if (renderCountDocumentPdfList == 0 && documentPdfListSuccess) {
      //this.filterDocumentList(documentPreferenceData?.documentPdfListData?.result?.data)
      this.setState({
        renderCountDocumentPdfList: 1,
        loadingStatus: false,
        documentTableList: this.filterDocumentList(documentPreferenceData?.documentPdfListData?.result?.data)
      })
    }
    else if (renderCountDocumentPdfList == 0 && documentPdfListErrorMessage == 'error') {
      this.setState({
        renderCountDocumentPdfList: 1,
        loadingStatus: false
      })
    }
  }

  handlegetDocumentList = () => {
    const { dashboardDocumentData, getDocument } = this.props
    const { renderCountDocumentList } = this.state
    const { documentListData, documentListSuccess, documentListErrorMessage } = dashboardDocumentData

    if (renderCountDocumentList == 0 && documentListSuccess) {
      if (documentListData?.metadataList?.length > 0) {
        getDocument({
          nodeId: documentListData?.metadataList[0]?.documentId,
          token: localStorage.getItem('token'),
        })
        this.setState({
          renderCountDocumentList: 1,
          loadingStatus: true
        })
      }
      else {
        this.setState({
          renderCountDocumentList: 1,
          loadingStatus: false
        })
      }
    }
    else if (renderCountDocumentList == 0 && documentListErrorMessage == 'error') {
      this.setState({
        renderCountDocumentList: 1,
        loadingStatus: false
      })
    }
  }

  handlegetDownloadDocument = () => {
    const { dashboardDocumentData, downloadedFileData, downloadCompleted } = this.props
    const { downloadDocumentRenderCount } = this.state
    const { documentListData } = dashboardDocumentData

    if (downloadDocumentRenderCount == 0 && downloadedFileData && downloadedFileData?.downloadedFile) {
      this.setState({
        downloadDocumentRenderCount: 1,
        loadingStatus: false,
      })
      this.documentOpenInNewTab(downloadedFileData.downloadedFile, `${documentListData?.metadataList[0]?.fileName}`)
      downloadCompleted()
    }
  }

  handleUpdateDocument = () =>{
    const {documentPreferenceData } = this.props
    if(this.state.downloadFlag == false){

    
    if(documentPreferenceData?.uploadDocumentSuccessStatus == true && documentPreferenceData?.uploadDocumentErrorMessage == ''){
        this.setState({
          show: true,
          showAlert:false
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
    }
    else if(documentPreferenceData?.uploadDocumentErrorMessage == 'Error' && documentPreferenceData?.uploadDocumentSuccessStatus == false ){
      this.setState({
        show: false,
        showAlert:true
      })
    }
  }
  }

  assignFileObject = (documentList) => {
    const documentListData = documentList?.map((item) => {

      return {
        ...item,
        fileObject: []
      }
    })
    return documentListData
  }

  getDocumentDescriptionList =(documentList)=>{
    let descriptionList = documentList.map((item,index)=>{
      return item.description
    })
    return descriptionList
  }

  filterDocumentList =(documentList)=>{
    let descriptionList = this.getDocumentDescriptionList(documentList)
    let onlyInAvailableApi = []
    let filterValue = []
    let tempFilterList = []
    let index = 0;
    for(let i=0; i < descriptionList.length; i++){
      if(defaultArray.includes(descriptionList[i])){
        filterValue= documentList.filter((value)=> value.description == defaultArray[index])
        if(filterValue.length > 0 ){
          index++;
          tempFilterList.push(
            {
              ...filterValue[0],
              fileObject: []
            }
          )
        }
      }
      else{
        filterValue= documentList.filter((value)=> value.description == descriptionList[i])
        onlyInAvailableApi.push(
                {
                  ...filterValue[0],
                  fileObject: []
                }
              )
      }
    }
    const  finalFilterList = [...tempFilterList,...onlyInAvailableApi]
    return finalFilterList
  }

  checkForZipUploadAndRefreshOnSuccessfulUpload = () => {
    const { documentPreferenceData } = this.props;
    const { zipUploadTotalFiles, zipUploadData } = this.state;
    
    const { isZipUploadFlow, zipUploadApicallsCounter } = documentPreferenceData?.zipUploadData || {};
    const { matchingItems } = zipUploadData || {};
    
    const isUploadComplete = matchingItems?.length > 0 && matchingItems.length === zipUploadTotalFiles;
    const isApiCallCounterMatched = zipUploadTotalFiles === zipUploadApicallsCounter;
    
    if (isZipUploadFlow && isUploadComplete && isApiCallCounterMatched) {
      // Refresh the window after the upload is completed
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {

      this.handlegetDocumentPdfList()
      this.handlegetDocumentList()
      this.handlegetDownloadDocument()
      this.handleUpdateDocument()

      
      this.checkForZipUploadAndRefreshOnSuccessfulUpload()
    }
  }

  getDownloadDocument = (formId, category) => {
    const { getDocumentId } = this.props
    const payload = {
      "formId": `${formId}`,
      "category": `${category}`,
      "templateName": "Template",
      "tokenType": "idToken"
    }
    getDocumentId(payload)
    this.setState({
      renderCountDocumentList: 0,
      downloadDocumentRenderCount: 0,
      loadingStatus: true,
      downloadFlag: true
    })
  }

  documentOpenInNewTab = (downloadedFile, saveAs) => {
    window.open(URL.createObjectURL(downloadedFile, saveAs));
  }

  uploadFileWithProgressBarCallBack = (response, index) => {
    if ((response.status == 200 || response.status == 201) && Object.keys(response.data)?.length > 0) {
      let documentListData = this.state.documentTableList
      documentListData[index].fileObject[0].percent = 100;
      this.setState({
        countState: this.state.countState + 1,
        successCountState: this.state.successCountState + 1,
        documentTableList: documentListData,
        selectedIndex: index
      })

    }
  }
  uploadFileWithProgressBarCatchCallBack = (status) => {
    this.setState({
      errorMessageFileUpload: { message: File_UPLOAD_ERROR_MESSAGE }
    })
  }

  uploadFilesWithProgressBar = (attachedFilesInfo, documentInfo, index) => {
    this.setState({
      successCountState: 0
    })
    let propertiesJson = {
      "entity": "VCM",
      "accountTypeGroup": "All_Account_Types",
      "productType": "Mutual_Fund",
      "templateName": "Template",
      "clientType": "Direct_Investor",
      "category": "Portfolio_Planner_Recommendation",
      "title": documentInfo?.title,
      "description": documentInfo?.product_description,
      "formId": this.getUpdatedFormId(documentInfo?.form_id_rw)
    }
    if (attachedFilesInfo) {
      const hundreadNumber = 100;
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * hundreadNumber) / total)
          if (percent < hundreadNumber) {
            this.state.documentTableList[index].fileObject[0].percent = percent;
            this.setState({
              countState: this.state.countState + 1
            })
          }
        }
      }

      const formData = new FormData();
      formData.append('file', attachedFilesInfo?.fileObject[0]?.fileData);
      formData.append('metadata', JSON.stringify(propertiesJson));
      fileUploadWithProgressBarUpdate(formData, index, options,
        this.uploadFileWithProgressBarCallBack,
        this.uploadFileWithProgressBarCatchCallBack);

    }
  }

  hasDigit = (str) => {
    return /\d/.test(str);
  }
  getUpdatedFormId = (formId) => {
    let tempFormId = `${formId}`

    if (tempFormId?.length > 0) {
      if (tempFormId.includes('V')) {
        if (this.hasDigit(tempFormId[tempFormId.length - 1])) {
          return `${tempFormId.substring(0, tempFormId.length - 1)}${parseInt(tempFormId[tempFormId.length - 1]) + 1}`
        }
      }
      else {
        return `${tempFormId}V1`
      }
    } else {
      return ''
    }
  }

  handleConfirmationClick = () => {
    const { documentTableList } = this.state
    const formId = this.getUpdatedFormId(this.state.documentTableList[this.state.selectedIndex].form_id_rw)
    const payload = {
      "product_id": documentTableList[this.state.selectedIndex].product_id,
      "risk_level": documentTableList[this.state.selectedIndex].risk_level,
      "form_id_rw": formId,
      "tokenType": "accessToken"
    }
    this.props.uploadDocumentConfirmation(payload)
    this.setState({
      downloadFlag: false
    })
  }

  hideZipUploadOption = () => {
    this.setState({showZipUploadOption: false})
  }

  // zip upload logic
  handleZipBulkUpload = (zipData) => {

    const { documentTableList } = this.state

    const matchingData = [];
    const nonMatchingData = [];
    const duplicateItems = [];
    
    // Create a set to track already seen items to identify duplicates
    const seenItems = new Set();

    zipData.forEach((zipItem, index) => {
        const matchIndex = documentTableList.findIndex(docItem => 
          (docItem.product_id === zipItem.product_id && 
          docItem.risk_level.replace(/\s+/g, '').toUpperCase() === zipItem.risk_level.replace(/\s+/g, '').toUpperCase())
        );

        if (matchIndex !== -1) {
          // Check if item has already been added to matchingData (duplicate check)
          const key = `${zipItem.product_id}-${zipItem.risk_level.replace(/\s+/g, '').toUpperCase()}`;
          if (seenItems.has(key)) {
              // If it's a duplicate, add to duplicateItems array
              duplicateItems.push({
                ...zipItem,
                matchingIndex: matchIndex
              });
          } else {
            // Mark this item as seen and add to matchingData
            seenItems.add(key);
            matchingData.push({
                ...zipItem,
                matchingIndex: matchIndex
            });
          }
        } else {
            // No match found, add to nonMatchingData
            nonMatchingData.push({ ...zipItem });
        }
    });

    // Upload API called for each of the matching items inside handleAttachedFiles function
    matchingData.forEach((item) => {
      if(item){
        this.handleAttachedFilesWithUploadApiCall(item.fileContent, item.matchingIndex, true)
      }
    });

    // Store the matching, non-matching, and duplicate data in local state
    this.setState(prevState => ({
      ...prevState,
      zipUploadData: {
        matchingItems: matchingData,
        nonMatchingItems: nonMatchingData,
        duplicateItems: duplicateItems  // Add the duplicateItems array here
      }
    }));
  }
  

  handleAttachedFilesWithUploadApiCall = (fileData, index, zipFileMatching=false) => {
    let documentListData = this.state.documentTableList
    documentListData[index].fileObject = [{
      fileData: fileData,
      percent: 0,
      zipFileMatching: zipFileMatching
    }]

    this.setState({
      documentTableList: documentListData
    })
  }

  renderTableBody() {
    const result = this.props?.documentPreferenceData?.documentPdfListData?.result
    if (result && Array.isArray(result.data)) {
      return this.state.documentTableList.map((item, index) => (
        <>
          {(item.product_id != "PRD:VCM:0007") &&  
          <Row key={index}>
          <Col>
            <Styles.FormData scope="col" role="columnheader" className="no-show">
              <Col  className='productnamelist' style={assignObj({ textAlign: 'left' })}>
                {item.product_id}
              </Col>
              <Col  style={assignObj({ textAlign: 'left' })}>
                {item.risk_level}
              </Col>
              <Col  style={assignObj({ textAlign: 'left' })}>
                {item.updatedAt ? item.updatedAt : '-'}
              </Col>
              <Col  className='listdata' style={assignObj({ textAlign: 'left' })}>
                {item.file_name}
              </Col>
              <Col >
                <p className='linkview' onClick={() => this.getDownloadDocument(item.form_id_rw, item.doc_category)}><img src={pdfIcon} />Download</p>
              </Col>

            {!this.state.isBulkUploding ? 
              <Col xs={2}>
                <FileUploader 
                  documentInfo={item} 
                  index={index} 
                  uploadFilesWithProgressBar={this.uploadFilesWithProgressBar} 
                  handleAttachedFiles={this.handleAttachedFiles} 
                  handleConfirmationClick={this.handleConfirmationClick}
                  hideZipUploadOption={this.hideZipUploadOption} 
                />
              </Col>

              : 

              <>
                <Col xs={2} className='listdata' style={assignObj({ textAlign: 'center' })}>
                  <p>{item?.fileObject[0]?.zipFileMatching ? item?.file_name : '-'} </p>
                </Col>

                <Col xs={2} style={assignObj({ textAlign: 'left' })}>
                  {
                    item?.fileObject[0]?.zipFileMatching ?
                      <ProgressBar now={item?.fileObject[0]?.percent} active label={`${item?.fileObject[0]?.percent}%`} />
                    :
                      <></>
                  }
                </Col>
              </>
            }
            </Styles.FormData>
          </Col>
        </Row>}
        </>
      ));
    }

  }
  onNotificationClose = () => {
    this.setState({
      show: false
    })
  }
  handleMessageBarClose = () => {
    this.setState({ showAlert: false });
  };


  updateZipuploadStateAndRefreshOnCompletion = () => {
 
    const { zipUploadTotalFiles } = this.state

    this.setState(prevState => ({ 
      ...prevState, 
      zipUploadTotalFiles: zipUploadTotalFiles + 1
    }));

  }
  
  zipUploadFilesWithProgressBarErrorCallBack = (status) => {
    // this.setState({
    //   errorMessageFileUpload: { message: File_UPLOAD_ERROR_MESSAGE }
    // })

    this.updateZipuploadStateAndRefreshOnCompletion()
   
  }


  zipUploadFilesWithProgressBarSuccessCallBack = (response, index) => {
    if ((response.status == 200 || response.status == 201) && Object.keys(response.data)?.length > 0) {
      let documentListData = this.state.documentTableList
      documentListData[index].fileObject[0].percent = 100;

      this.setState({
        documentTableList: documentListData,
      })

      // Call the upload api for the document after the document api return success

      const formId = this.getUpdatedFormId(documentListData[index].form_id_rw);
      const payload = {
        "product_id": documentListData[index].product_id,
        "risk_level": documentListData[index].risk_level,
        "form_id_rw": formId,
        "tokenType": "accessToken",
      };
  
      let zipUploadData = { 
        isZipUploadFlow: true, 
        zipUploadApicallsCounter: this.state.zipUploadTotalFiles + 1
      }


      this.props.uploadDocumentConfirmation(payload, zipUploadData);



      this.updateZipuploadStateAndRefreshOnCompletion()
    }
  }


  zipUploadFilesWithProgressBar = (attachedFilesInfo, index) => {

    if (attachedFilesInfo) {

      let propertiesJson = {
        "entity": "VCM",
        "accountTypeGroup": "All_Account_Types",
        "productType": "Mutual_Fund",
        "templateName": "Template",
        "clientType": "Direct_Investor",
        "category": "Portfolio_Planner_Recommendation",
        "title": attachedFilesInfo?.title,
        "description": attachedFilesInfo?.product_description,
        "formId": this.getUpdatedFormId(attachedFilesInfo?.form_id_rw)
      }
      const hundreadNumber = 100;
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * hundreadNumber) / total)
          if (percent < hundreadNumber) {
            this.state.documentTableList[index].fileObject[0].percent = percent;
          }
        }
      }

      const formData = new FormData();
      formData.append('file', attachedFilesInfo?.fileObject[0]?.fileData);
      formData.append('metadata', JSON.stringify(propertiesJson));
      fileUploadWithProgressBarUpdate(formData, index, options,
        this.zipUploadFilesWithProgressBarSuccessCallBack,
        this.zipUploadFilesWithProgressBarErrorCallBack);

    }
  }

  handleBulkZipSubmit = () => {
    const { zipUploadData, documentTableList } = this.state;
  
    if (!zipUploadData || !Array.isArray(zipUploadData.matchingItems)) {
      console.error("no matchingItems in the zip");
      return;
    }
  
    this.setState({isSubmitButtonDisabled: true})
    
    zipUploadData.matchingItems.forEach((item, index)=>{
      if(item){
        this.zipUploadFilesWithProgressBar(documentTableList[item.matchingIndex], item.matchingIndex)
      }
    })

  
  };
  

  setTheDocumentDataListToInitailState = (index) => {
    this.setState(prevState => {
      const documentListData = [...prevState.documentTableList];
      documentListData[index] = {
        ...documentListData[index],
        fileObject: []  // Reset the fileObject
      };
  
      return { documentTableList: documentListData };
    });
  };

  handleBulkZipCancel = () => {
    // Reset the state to initial
    this.state.zipUploadData.matchingItems.forEach((item)=>{
      if(item && item.matchingIndex !== undefined){
        this.setTheDocumentDataListToInitailState(item.matchingIndex)
      }
    })

    this.setState(prevState => ({ 
      ...prevState, 
      isBulkUploding: false,
      zipUploadData: {
        matchingItems: [],
        nonMatchingItems: [],
        duplicateItems: []
      }
    }));

  }

  render() {
    const { loadingStatus, show, showAlert, zipUploadLoader, zipUploadData, showZipUploadOption, isSubmitButtonDisabled } = this.state;

    const loader = loadingStatus || zipUploadLoader

    return (
      <>
        {loader &&
          <WSpinner
            aria-busy="true"
            aria-live="polite"
            aria-label="Please wait while we load"
            loading={loader} />
        }
        {showAlert && (
          <WMessageBar text={{ info: '' }} error={true} onClose={this.handleMessageBarClose} />
        )}

        {show &&
          <StatusMessage onClose={this.onNotificationClose} text={{ message: this.state.uploadDocumentSuccessMessage, info: null }} />
        }

        <div className="borderDesign">
          <div className="container">
            <div className="transContainer mt-5">
              {
                showZipUploadOption ? 
                <Container fluid>
                  <Row className="d-flex justify-content-end">
                    <Col xs="auto">
                      <BulkZipUploadComponent 
                        showBulkUploadingSection={this.showBulkUploadingSection}
                        handleZipBulkUpload={this.handleZipBulkUpload}
                      />
                    </Col>
                  </Row>
                </Container>
                :
                <></>
              }

              {/* <button onClick={this.uploadZipHandler}>Upload zip folder</button> */}
              <br />
              <br />
              <br />

              <Row className="transActivity">
                <Col>
                  <Styles.FormHeader scope="col" role="columnheader" className="no-show transanction-Table">
                    <Col style={assignObj({ textAlign: 'left' })}>
                      {HEADER.PRODUCTID}
                    </Col>
                    <Col style={assignObj({ textAlign: 'left' })}>
                      {HEADER.RISKLEVEL}
                    </Col>
                    <Col style={assignObj({ textAlign: 'left' })}>
                      {HEADER.DATE}
                    </Col>
                    <Col style={assignObj({ textAlign: 'left' })}>
                      {HEADER.FILE_NAME}
                    </Col>
                    <Col></Col>
                    {
                    !this.state.isBulkUploding ? <Col ></Col>
                    :
                    <>
                      <Col style={assignObj({ textAlign: 'left' })}>
                        Matching File
                      </Col>

                      <Col style={assignObj({ textAlign: 'left' })}>
                        Upload Progress
                      </Col>
                    </>
                    }
                  </Styles.FormHeader>
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.renderTableBody()}
                </Col>

              </Row>

              <Row>

                { zipUploadData.duplicateItems &&
                  zipUploadData.duplicateItems.length > 0 ? (
                    <Col>
                      <h5>There are {zipUploadData.duplicateItems.length} in the uploaded zip file</h5>
                      <h6>Here are the Duplicate files</h6>
                      <ol>
                        {
                          zipUploadData.duplicateItems.map((item, index) => (
                            <li key={index}>
                              <strong>{item.fileName}</strong>
                            </li>
                          ))
                        }
                      </ol>
                    </Col>
                  ) : (
                    <></>
                  )
                }

              </Row>

                <Row>
                  <hr></hr>
                 { zipUploadData.nonMatchingItems &&
                    zipUploadData.nonMatchingItems.length > 0 ? (
                      <Col>
                        <h4 style={{textDecoration: 'underline'}}>Bulk upload pdfs vs product matching status:</h4>
                        <h5>Matching Files are {zipUploadData.matchingItems.length} and Non Matching Files are {zipUploadData.nonMatchingItems.length}</h5>
                        <h6>Here are the non matching files from the uploaded zip file</h6>
                        <ol>
                          {
                            zipUploadData.nonMatchingItems.map((item, index) => (
                              <li key={index}>
                                <strong>{item.fileName}</strong>
                              </li>
                            ))
                          }
                        </ol>
                      </Col>
                    ) : (
                      <></>
                    )
                  }

                  {
                    zipUploadData.matchingItems && zipUploadData.matchingItems.length > 0 && 
                    <>
                    <Col xs={2} >
                      <WButton
                        variant="cancel"
                        buttontext="Cancel"
                        id="submit-zip-upload-cancel"
                        buttonstyle={assignObj(cancelButtonStyles)}
                        onClick={this.handleBulkZipCancel}
                        disabled={isSubmitButtonDisabled}
                      />
                    </Col>

                    <Col xs={2}>
                      <WButton
                        submit="submit"
                        buttontext="Submit"
                        id="submit-zip-upload"
                        onClick={this.handleBulkZipSubmit}
                        disabled={isSubmitButtonDisabled}
                      />
                    </Col>
                    </>
                  }


                </Row>
              
            </div>
          </div>
        </div>
      </>
    );
  }
}
PortfolioPlannerPdf.defaultProps = {
  getDocumentPdfList: () => {},
  
};
export default PortfolioPlannerPdf;
